import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import {
  enableProdMode,
  ErrorHandler,
  importProvidersFrom,
} from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  provideRouter,
  TitleStrategy,
  withRouterConfig,
} from '@angular/router';
import { CrmAuthInterceptor, crmProvideAuth } from 'common-module/auth';
import { CrmAppConfig } from 'common-module/core';
import { crmProvideEndpoint } from 'common-module/endpoint';
import { CrmLayoutV2Provider, crmProvideLayout } from 'common-module/layout-v2';
import { crmProvideMessage } from 'common-module/message';
import { crmProvideModal } from 'common-module/modal';
import { crmProvideTranslate } from 'common-module/translate';
import { CrmUserService } from 'common-module/user';
import { enableMapSet } from 'immer';
import { Settings } from 'luxon';
import { en_US, NZ_DATE_CONFIG, NZ_I18N } from 'ng-zorro-antd/i18n';
import { NzAutosizeDirective } from 'ng-zorro-antd/input';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';
import { crmProvideVersion } from 'common-module/version';
import { provideNzConfig } from 'ng-zorro-antd/core/config';

import { OrganizationInterceptor } from '~/api/organization/organization.interceptor';
import { LayoutProvider } from '~/shared/crm/layout/layout.provider';
import { UserService } from '~/api/user/user.service';
import { ErrorInterceptor } from '~/shared/interceptors/error.interceptor';
import { HookInterceptor } from '~/shared/interceptors/hook.interceptor';
import { JwtInterceptor } from '~/shared/interceptors/jwt.interceptor';
import { ErrorHandlerService } from '~/shared/services/error-handler.service';
import { TitleService } from '~/shared/services/title.service';
import { staticHolderProvider } from '~/shared/static/static-holder';

import { AppComponent } from './app/app.component';
import { appConfig } from './config/app.config';
import { appRoutes } from './app/app.routes';
import { authConfig } from './config/auth.config';
import { endpointConfig } from './config/endpoint.config';
import { gaInitCommand } from './config/ga.config';
import { translocoConfig } from './config/translate.config';
import { environment } from './environments/environment';
import { layoutConfig } from './config/layout.config';

if (environment.production) {
  enableProdMode();
}

Settings.throwOnInvalid = true;

enableMapSet();

// This is workaround for NzAutosizeDirective which doesn't work with different height than 32px
// inputGap 18 is working with 40px textarea height
Object.defineProperty(NzAutosizeDirective.prototype, 'inputGap', {
  enumerable: false,
  configurable: false,
  get() {
    return 18;
  },
  set() {},
});

bootstrapApplication(AppComponent, {
  providers: [
    // NG
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    provideRouter(
      appRoutes,
      withRouterConfig({ paramsInheritanceStrategy: 'always' }),
    ),
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    { provide: TitleStrategy, useClass: TitleService },
    { provide: HTTP_INTERCEPTORS, useClass: CrmAuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HookInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: OrganizationInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },

    // NZ
    { provide: NZ_I18N, useValue: en_US },
    { provide: NZ_DATE_CONFIG, useValue: { firstDayOfWeek: 1 } },
    provideNzConfig({
      select: { nzSuffixIcon: 'icons:down-line', nzOptionHeightPx: 38 },
    }),

    // SWP
    importProvidersFrom(
      NgxGoogleAnalyticsModule.forRoot(environment.ga, [gaInitCommand]),
    ),
    crmProvideEndpoint(endpointConfig),
    crmProvideModal(),
    crmProvideMessage({ duration: 2000 }),
    crmProvideVersion({ enabled: true, browserCacheDisabled: true }),
    crmProvideAuth(authConfig),
    crmProvideTranslate({}, translocoConfig),
    crmProvideLayout(layoutConfig),
    { provide: CrmLayoutV2Provider, useExisting: LayoutProvider },
    { provide: CrmUserService, useExisting: UserService },
    { provide: CrmAppConfig, useValue: appConfig },

    // APP
    staticHolderProvider,
  ],
}).catch((err) => console.error(err));
