import { CrmLayoutMenuConfig } from 'common-module/layout-v2';
import type { CrmMenuItem } from 'common-module/menu';

import { environment } from '~/environments/environment';

/**
 * Resolve menu config by enabled features
 */
const resolveMenuConfig = () => {
  const items: CrmMenuItem[] = [
    {
      key: 'dashboard.title',
      icon: { base: 'icons:mosaic-line', selected: 'icons:mosaic-fill' },
      routeKey: 'dashboard',
      children: [
        { key: 'dashboard.toc.titleShort', routeKey: 'toc' },
        { key: 'dashboard.cocm.title', routeKey: 'cocm' },
      ],
    },
    {
      key: 'layout.menu.users',
      icon: { base: 'icons:group-line', selected: 'icons:group-fill' },
      routeKey: 'users',
    },
  ];

  if (environment.featureFlags.events.enabled) {
    items.push({
      key: 'events.title',
      icon: { base: 'icons:calendar-line', selected: 'icons:calendar-fill' },
      routeKey: 'events',
    });
  }

  items.push({
    key: 'layout.menu.reports',
    icon: { base: 'icons:document-line', selected: 'icons:document-fill' },
    routeKey: 'reports',
  });

  return items;
};

/**
 * Menu items config
 */
export const menuConfig: CrmLayoutMenuConfig = {
  items: resolveMenuConfig(),
};
