import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';

import { AvatarComponent } from '~/shared/components/avatar/avatar.component';
import { FormatDateTimePipe } from '~/shared/pipes/format-date-time.pipe';

import { Notification } from './notification';

@Component({
  standalone: true,
  selector: 'app-notification',
  template: `
    <div class="header">
      <div class="author centered">
        <app-avatar [src]="author().image" size="small"></app-avatar>
        <span class="ml-1">{{ author().name }}</span>
      </div>
      <div class="timestamp">
        {{ timestamp() | formatDateTime }}
      </div>
    </div>
    <div class="message">{{ message() }}</div>
  `,
  styles: [
    `
      :host {
        display: block;
        padding: 16px;
        border-radius: 8px;
        color: #595959;
      }

      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 16px;
        font-size: 12px;
      }
    `,
  ],
  imports: [FormatDateTimePipe, AvatarComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationComponent {
  readonly notification = input.required<Notification>();

  protected author = computed(() => this.notification().author);
  protected timestamp = computed(() => this.notification().timestamp);
  protected message = computed(() => this.notification().message);
}
