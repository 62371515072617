import { HttpClient } from '@angular/common/http';
import * as i0 from '@angular/core';
import { inject, ApplicationRef, Injectable, NgModule } from '@angular/core';
import { SwUpdate, SwRegistrationOptions } from '@angular/service-worker';
import { CrmConfigService, toBoolean, mergeDeep, CrmModuleConfigToken } from 'common-module/core';
import { CrmMessageService } from 'common-module/message';
import md5 from 'md5';
import { BehaviorSubject, from, race, interval, concat, of } from 'rxjs';
import { filter, tap, first, delay, takeUntil, catchError, take, mergeMap } from 'rxjs/operators';
const defaultVersionConfiguration = {
  enabled: false,
  reloadOnUpdate: false,
  serviceWorkerEnabled: false,
  versionPollingInterval: 30 * 1000,
  browserCacheDisabled: false,
  registrationStrategy: 'registerWhenStable:30000'
};
const APP_VERSION_STORAGE_KEY = '_app-version-hash';
class CrmVersionDetectorService {
  constructor() {
    this.sw = inject(SwUpdate, {
      optional: true
    });
    this.appRef = inject(ApplicationRef);
    this.messageService = inject(CrmMessageService);
    this.http = inject(HttpClient);
    this.configService = inject(CrmConfigService);
    this.checkingVersion = false;
    this._appUpdateAvailable$ = new BehaviorSubject(false);
    this._swEnabled$ = new BehaviorSubject(false);
    this.config = this.configService.mergeConfig(defaultVersionConfiguration, 'modules.version');
  }
  get appUpdateAvailable$() {
    return this._appUpdateAvailable$.asObservable();
  }
  get appUpdated$() {
    return this._appUpdated$;
  }
  get swEnabled$() {
    return this._swEnabled$.asObservable();
  }
  reloadApp() {
    this.messageService.loading('version.reloadingAppNewVersion');
    this._swEnabled$.subscribe(available => {
      if (available && this.sw) {
        this.sw.activateUpdate().then(() => {
          document.location.reload();
        });
        return;
      }
      if (this.newAppData) {
        this.updateStorage(this.newAppData);
      }
      document.location.reload();
    });
  }
  init() {
    this._appStable$ = this.appRef.isStable;
    if (!this.sw || !this.sw.isEnabled) {
      this.fallbackVersionDetection();
      return;
    }
    this._swEnabled$.next(this.sw.isEnabled);
    this.initServiceWorker();
  }
  initServiceWorker() {
    if (!this.sw) {
      return;
    }
    this._appUpdated$ = from(this.sw.activateUpdate()).pipe(toBoolean());
    this._swUpdateAvailable$ = this.sw.versionUpdates.pipe(filter(evt => evt.type === 'VERSION_READY'));
    this._swUpdateAvailable$.pipe(toBoolean(), tap(() => {
      if (this.config.reloadOnUpdate) {
        this.reloadApp();
      }
    })).subscribe(available => this._appUpdateAvailable$.next(available));
    // Service Worker enabled value, delayed by 30s, firing only true value
    const delayedActivated$ = this._swEnabled$.pipe(first(enabled => enabled), delay(30000));
    // Fire when ng app is stable
    const appStable$ = this._appStable$.pipe(first(isStable => isStable));
    const versionPollInterval = this.getPollingInterval();
    const appInitRace$ = race(appStable$, delayedActivated$);
    const pollInterval$ = interval(versionPollInterval);
    const versionPolling$ = concat(appInitRace$, pollInterval$).pipe(this.takeUntilUpdateAvailable());
    versionPolling$.subscribe(() => {
      if (this.checkingVersion || !this.sw) {
        return;
      }
      this.sw.checkForUpdate().then(() => this.checkingVersion = false).catch(err => {
        console.error('Error checking for version', err);
      });
    });
  }
  takeUntilUpdateAvailable() {
    return takeUntil(this._appUpdateAvailable$.pipe(first(v => v)));
  }
  fallbackVersionDetection() {
    const versionPollInterval = this.getPollingInterval();
    const ngswConfig$ = this.http.get(`ngsw.json?t=${+new Date()}`).pipe(catchError(() => of(null)));
    const versionPoll$ = concat(ngswConfig$.pipe(take(1)), interval(versionPollInterval).pipe(mergeMap(() => ngswConfig$)));
    let isFirstCheck = true;
    versionPoll$.pipe(this.takeUntilUpdateAvailable()).subscribe({
      next: config => {
        if (!config) {
          return;
        }
        const hashVal = Object.values(config.hashTable).reduce((p, val) => `${p},${val}`, '');
        const fileHash = md5(hashVal);
        const appVersionData = this.getStoredAppData();
        if (!appVersionData || isFirstCheck && this.config.browserCacheDisabled) {
          this.updateStorage({
            appHash: fileHash,
            timestamp: config.timestamp
          });
          isFirstCheck = false;
          return;
        }
        isFirstCheck = false;
        if (appVersionData.appHash !== fileHash) {
          this._appUpdateAvailable$.next(true);
          if (this.config.browserCacheDisabled) {
            this.updateStorage({
              appHash: fileHash,
              timestamp: config.timestamp
            });
            return;
          }
          this.newAppData = {
            appHash: fileHash,
            timestamp: config.timestamp
          };
        }
      }
    });
  }
  getStoredAppData() {
    const appVersionDataRaw = localStorage.getItem(APP_VERSION_STORAGE_KEY);
    try {
      return appVersionDataRaw ? JSON.parse(appVersionDataRaw) : null;
    } catch (err) {
      return null;
    }
  }
  getPollingInterval(defaultValue = 30 * 60 * 1000) {
    return this.config.versionPollingInterval ?? defaultValue;
  }
  updateStorage(data) {
    localStorage.setItem(APP_VERSION_STORAGE_KEY, JSON.stringify(data));
  }
  static {
    this.ɵfac = function CrmVersionDetectorService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CrmVersionDetectorService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: CrmVersionDetectorService,
      factory: CrmVersionDetectorService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmVersionDetectorService, [{
    type: Injectable
  }], null, null);
})();
const crmProvideVersion = config => {
  const mergedConfig = mergeDeep(defaultVersionConfiguration, config ?? {});
  const swRegistrationOptions = {
    enabled: mergedConfig.serviceWorkerEnabled,
    registrationStrategy: mergedConfig.registrationStrategy
  };
  return [CrmVersionDetectorService, {
    provide: CrmModuleConfigToken,
    useValue: {
      version: mergedConfig
    },
    multi: true
  }, {
    provide: SwRegistrationOptions,
    useValue: swRegistrationOptions
  }];
};

/**
 * @deprecated will be removed in v9
 * @see {crmProvideVersion}
 */
class CrmVersionModule {
  /**
   * Initialize module with service worker configuration given using
   * opts argument or {@link CrmVersionConfig}.
   *
   * @param config
   */
  static forRoot(config) {
    return {
      ngModule: CrmVersionModule,
      providers: crmProvideVersion(config)
    };
  }
  static {
    this.ɵfac = function CrmVersionModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CrmVersionModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: CrmVersionModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmVersionModule, [{
    type: NgModule
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { CrmVersionDetectorService, CrmVersionModule, crmProvideVersion, defaultVersionConfiguration };
