<div class="ehr-action-sidebar__menu">
  @for (item of items; track item.key) {
    <div
      class="ehr-action-sidebar__menu-item"
      (click)="selectItem(item)"
      [class.selected]="selectedItem()?.key === item.key"
    >
      <nz-badge [nzDot]="item.showBadge()">
        <span
          nz-icon
          [nzType]="
            selectedItem()?.key === item.key ? item.selectedIcon : item.icon
          "
          nzTheme="outline"
        ></span>
      </nz-badge>
    </div>
  }
</div>

@if (selectedItem(); as item) {
  <div class="ehr-action-sidebar__content">
    @if (item.isLoading()) {
      <crm-loading></crm-loading>
    } @else {
      @if (item.title(); as title) {
        <div class="ehr-action-sidebar__content-title">
          <app-title [message]="title" level="4" weight="bold"></app-title>
        </div>
      }

      @if (item.render(); as render) {
        @if (render.content) {
          <ng-container
            [crmRenderer2]="render.content"
            [context]="render.contentContext"
          ></ng-container>
        }
      }
    }
  </div>
}
