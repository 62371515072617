import { inject, Injectable } from '@angular/core';
import { CanMatchFn, Route, Router, UrlSegment } from '@angular/router';
import { catchError, map, of, switchMap } from 'rxjs';

import {
  Organization,
  OrganizationService,
} from '~/api/organization/organization.service';
import { UserService } from '~/api/user/user.service';

@Injectable({ providedIn: 'root' })
export class AppGuard {
  private user = inject(UserService);
  private organization = inject(OrganizationService);
  private router = inject(Router);

  /**
   * Function which matches main app route.
   *
   * If there is no organization id in route and first segment is known segment
   * then redirect with saved organization if defined.
   *
   * Check if user is logged in and load organization otherwise.
   */
  canMatch(_: Route, segments: UrlSegment[]) {
    const firstSegmentPath: string | undefined = segments[0]?.path;

    if (firstSegmentPath && this.isLegacyPath(firstSegmentPath)) {
      return this.redirectWithOrganizationIfDefined(segments);
    }

    return this.resolveUser(firstSegmentPath);
  }

  /**
   * Check if segment path is one of the legacy paths
   *
   * @param path
   * @private
   */
  private isLegacyPath(path: string) {
    return ['users', 'forms', 'reports'].includes(path);
  }

  /**
   * First load current organization from storage,
   * if saved organization exists, return new url tree with saved organization as first segment with other segments,
   * otherwise load user organizations and redirect to first organization
   *
   * @param segments
   * @private
   */
  private redirectWithOrganizationIfDefined(segments: UrlSegment[]) {
    const currentOrganization =
      this.organization.loadCurrentOrganizationFromStorage();

    if (currentOrganization) {
      return this.router.createUrlTree([
        `/${currentOrganization}`,
        ...segments.map(({ path }) => path),
      ]);
    }

    return this.resolveUser();
  }

  /**
   * Resolve user organizations and ensure url contains organization id
   *
   * @param organization
   * @private
   */
  private resolveUser(organization?: string) {
    return this.user.user$.pipe(
      switchMap((user) => this.organization.getOrganizations(user)),
      map((organizations) =>
        this.ensureOrganization(organization, organizations),
      ),
      catchError(() => of(false)),
    );
  }

  /**
   * Ensure organization is included in route segments.
   * If organization is defined, return true
   * Otherwise redirect to saved organization or first available organization id
   *
   * @param organization
   * @param organizations
   * @private
   */
  private ensureOrganization(
    organization: string | undefined,
    organizations: Organization[],
  ) {
    if (organization) {
      return true;
    }

    const currentOrganization =
      this.organization.loadCurrentOrganizationFromStorage();

    this.router
      .navigate([currentOrganization ?? organizations[0].id])
      .then(() => {});
    return false;
  }
}

/**
 * App guard factory function
 */
export const appGuardFn = (): CanMatchFn => (route, segments) =>
  inject(AppGuard).canMatch(route, segments);
