import { computed, inject, Injectable, Signal, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { CrmLayoutSiderControl } from 'common-module/layout-v2';

import { OrganizationService } from '~/api/organization/organization.service';

@Injectable({ providedIn: 'root' })
export class LayoutOrganizationProvider {
  readonly organizationService = inject(OrganizationService);

  organizations = toSignal(this.organizationService.organizations$, {
    requireSync: true,
  });
  currentOrganization = toSignal(
    this.organizationService.currentOrganization$,
    { requireSync: true },
  );

  protected router = inject(Router);

  pickerSize$(options: {
    siderControl?: CrmLayoutSiderControl;
  }): Signal<'compact' | 'default'> {
    const { siderControl } = options;

    if (!siderControl) {
      return signal('compact');
    }

    return computed(() => (siderControl.collapsed() ? 'compact' : 'default'));
  }

  setOrganization(organization: string) {
    if (this.organizationService.setAndSaveOrganization(organization)) {
      this.router.navigate([organization, 'users']).then(() => {});
    }
  }
}
